import React from "react";

import "./App.css";

import { SearchPanel, ListItem } from "./components";

class App extends React.Component {
  state = {
    listAnswers: [
      {
        id: 1,
        question: "Пациент (по Хендерсу)",
        answer: "индивидум, имеющий фундаментальные потребности",
      },
      {
        id: 2,
        question: "Потребность",
        answer:
          "физиологический или психологический дефицит, который человек испытывает на протяжении всей жизни",
      },
      {
        id: 3,
        question: "К физиологическим потребности относится",
        answer: "дышать",
      },
      {
        id: 4,
        question: "К объективному методу обследования относится",
        answer: "измерение роста и массы тела",
      },
      {
        id: 5,
        question: "Настоящая явная физиологическая проблема пациента",
        answer: "головная боль",
      },
      {
        id: 6,
        question: "Потенциальная физиологическая проблема пациента",
        answer: "риск обезвоживания организма",
      },
      {
        id: 7,
        question: "Социальная потребность пациента",
        answer: "признание",
      },
      {
        id: 8,
        question: "К Физиологическим потребностям согласно иерархии Маслоу",
        answer: "дыхание",
      },
      {
        id: 9,
        question:
          "У пациента с легочным кровотечением в первую очередь нарушено удовлетворение потребности",
        answer: "дыхание",
      },
      {
        id: 10,
        question:
          "Потенциальной проблемой пациента при нарушении потребности в труде и отдыхе ",
        answer: "может быть риск потери работы",
      },
      {
        id: 11,
        question: "Характерной фантомной болью является",
        answer: "боль, имитирующая отсутствующую конечность",
      },
      {
        id: 12,
        question:
          "Для определения интенсивности боли при проведении первичной оценки можно использовать",
        answer: "визуально-аналоговую или цифровую шкалу",
      },
      {
        id: 13,
        question:
          "При первичной оценке потребности в физиолог отправлениях необходимо обратить внимание",
        answer: "на количество выделенной мочи за сутки",
      },
      {
        id: 14,
        question:
          "Проблема пациента при нарушении удовлетворения потребности в движении может быть связана",
        answer: "с риском развития пролежней",
      },
      {
        id: 15,
        question: "К индивид мерам профилактики риска падений",
        answer: "подбор вспомогательных средств передвижения",
      },
      {
        id: 16,
        question:
          "Вопрос, который необходимо задать пожилому человеку для оценки риска падений",
        answer: "были ли падения в течение года",
      },
      {
        id: 17,
        question: "К мерам по снижению риска падений в стационаре относится",
        answer:
          "минимальная длительность госпитализации и ранняя мобилизация пацтент",
      },
      {
        id: 18,
        question: "К мероприятию безопасности быта относится",
        answer: "фиксация проводов за плинтус",
      },
      {
        id: 19,
        question: "К общим мерам профилактики падений относится",
        answer: "организация безопасной окружающей среды",
      },
      {
        id: 20,
        question: "К последствию падения относится",
        answer: "травма",
      },
      {
        id: 21,
        question: "Потребность в защите (по Маслоу)",
        answer: "уровень 2",
      },
      {
        id: 22,
        question: "Показания к проведению трахеостомии",
        answer: "опухоль гортани",
      },
      {
        id: 23,
        question: "Препарат для обработки кожи вокруг стомы",
        answer: "паста Стомагезив",
      },
      {
        id: 24,
        question: "Стома подвздошной кишки",
        answer: "илеостома",
      },
      {
        id: 25,
        question:
          "Отверстие, при котором отдел ободочной кишки выводится на переднюю брюшную стенку",
        answer: "колостома",
      },
      {
        id: 26,
        question: "Ежедневный уход за пациентами со стомой кишечника включает ",
        answer: "уход за кожей вокруг стомы",
      },
      {
        id: 27,
        question: "Показания к наложению постоянной колостомы",
        answer: "опухоль кишечника",
      },
      {
        id: 28,
        question: "Продукты, усиливающие перистальтику кишечника",
        answer: "богатые клетчаткой",
      },
      {
        id: 29,
        question:
          "Психологическая проблема стомированного пациента послеоперационного периода",
        answer: "депрессия",
      },
      {
        id: 30,
        question: "Для обеззараживания кожи вокруг трахеостомической трубки",
        answer: "применяют раствор хлоргексидина",
      },
      {
        id: 31,
        question: "Для подачи кислорода медицинский увлажнитель заполняют",
        answer: "дистиллированной водой",
      },
      {
        id: 32,
        question: "Дренажный мешок при кишечной стоме следует опорожнять",
        answer: "по заполнению каловыми массами и газами на 1/2 или 1/3 объема",
      },
      {
        id: 33,
        question: "Объем пищи, вводимый через гастростому",
        answer: "постепенно увеличивают",
      },
      {
        id: 34,
        question: "Показания к наложению цистостомы",
        answer: "травма мочеиспускательного канала",
      },
      {
        id: 35,
        question: "Для защиты кожи от раздражения при наличии стомы применяют",
        answer: "паста Лассара",
      },
      {
        id: 36,
        question:
          "Двухкомпонентный калоприемник позволяет менять адгезивную пластину",
        answer: "1 раз в 3-4 дня",
      },
      {
        id: 37,
        question: "Цель наложения колостомы",
        answer: " выведение кала и газов",
      },
      {
        id: 38,
        question: "Для промывания мочевого пузыря при цистостоме используют",
        answer: "шприц Жане",
      },
      {
        id: 39,
        question: "Для определения размера стомы используют",
        answer: "трафарет",
      },
      {
        id: 40,
        question: "Частота смены повязок у стомированных пациентов",
        answer: "1 раз в день",
      },
      {
        id: 41,
        question: "Раствор, используемый для промывания мочевого пузыря",
        answer: "0,05% раствора хлоргексидина биглюконата",
      },
      {
        id: 42,
        question: "Частота санации трахеостомической трубки",
        answer: "не менее 2 раз в день",
      },
      {
        id: 43,
        question: "Стомы различают",
        answer: "временные и постоянные",
      },
      {
        id: 44,
        question:
          "Консистенция каловых масс стомированного пациента зависит от",
        answer: "места локализации участка выведенного отдела кишечника",
      },
      {
        id: 45,
        question: "Цель наложения эпицистостомы",
        answer: "выведение мочи",
      },
      {
        id: 46,
        question:
          "Невозможность дышать естественным путем является показанием к",
        answer: "трахеостоме",
      },
      {
        id: 47,
        question: "При колостоме выделяется",
        answer: "оформленный кал",
      },
      {
        id: 48,
        question: "Реабилитация пациентов со стомами заключается в",
        answer: "психологической адаптации",
      },
      {
        id: 49,
        question: "Кормление через гастростому относится к питанию",
        answer: "искусственному",
      },
      {
        id: 50,
        question:
          "Кормление через гастростому в медицинской организации входит в обязанности",
        answer: "медицинской сестры",
      },
      {
        id: 51,
        question: "Температура пищи при кормлении пациента через гастростому",
        answer: "38-40 градусов",
      },
      {
        id: 52,
        question: "Стома слепой кишки называется",
        answer: "цекостома",
      },
      {
        id: 53,
        question: "Илеостому располагают в области живота",
        answer: "правая нижняя",
      },
      {
        id: 54,
        question: "Постоянную колостому выводят при",
        answer: "опухолях ободочной кишки",
      },
      {
        id: 55,
        question: "Колостома накладывается с целью",
        answer: "временного или постоянного отведения фекалий",
      },
      {
        id: 56,
        question: "Показание для наложения гастростомы",
        answer: "ожог пищевода",
      },
      {
        id: 57,
        question: "Для катетеризации мочевого пузыря при цистостомии вводится",
        answer: "головчатый катетер Малеко",
      },
      {
        id: 58,
        question:
          "Для дренирования надлобкового свища при урологических операциях устанавливается ",
        answer: "катетер Пеццера",
      },
      {
        id: 59,
        question:
          "В каком направлении снимается использованный дренажный мешок с кишечной стомы",
        answer: "сверху вниз",
      },
      {
        id: 60,
        question:
          "В каком направлении накладывается дренажный мешок на колостому",
        answer: "снизу вверх",
      },
      {
        id: 61,
        question: "Рекомендуемое положение для пациента с затрудненным дыхания",
        answer: "Фаулера",
      },
      {
        id: 62,
        question: "Положение, при котором расслабляются мышцы живота",
        answer: "Фаулера",
      },
      {
        id: 63,
        question: "При затрудненном дыхании пациент занимает положение",
        answer: "ортопноэ (сидя с опущенными ногами)",
      },
      {
        id: 64,
        question: "Положение ортопноэ предусматривает",
        answer:
          "вынужденное сидячее положение тела пациента с опущенными вниз ногами",
      },
      {
        id: 65,
        question:
          "К какой патологии может привести нарушение правил биомеханики медсестры",
        answer: "остеохондроз",
      },
      {
        id: 66,
        question:
          "Какой максимальный вес может перемещать медработник весом 60 кг",
        answer: "90",
      },
      {
        id: 67,
        question: "Положение Симса",
        answer: "промежуточное положение между лёжа на боку и лёжа на животе",
      },
      {
        id: 68,
        question: "Положение Фаулера",
        answer: "полулёжа полусидя",
      },
      {
        id: 69,
        question:
          "Для предупреждения провисания стопы пациента в положении Фаулера упор для стоп ставится под углом",
        answer: "90",
      },
      {
        id: 70,
        question: "Перемещение пациента в постели каждые 2 часа обеспечивает",
        answer: "профилактику пролежней",
      },
      {
        id: 71,
        question:
          "Среднее положение Фаулера обеспечивает высота изголовья кровати",
        answer: "60",
      },
      {
        id: 72,
        question: "Соблюдение биомеханики тела позволяет предотвратить",
        answer: "падение",
      },
      {
        id: 73,
        question: "Правильная биомеханика тела медсестры позволяет",
        answer: "предотвращать у себя травмы позвоночника",
      },
      {
        id: 74,
        question:
          "Пациент не может самостоятельно повернуться или изменить своё положение в постели при",
        answer: "пассивном положении",
      },
      {
        id: 75,
        question:
          "Пациент не может самостоятельно передвигаться. Какой уровень помощи при перемещении с кровати на стул ему необходим",
        answer: "максимальная помощь",
      },
      {
        id: 76,
        question: "Любое перемещение пациента в постели необходимо начинать с",
        answer: "ознакомления с действиями",
      },
      {
        id: 77,
        question: "Центр тяжести у человека находится",
        answer: "таз",
      },
      {
        id: 78,
        question:
          "Одним из важнейших принципов оказания помощи маломобильным пациентам",
        answer: "как можно дольше сохранять самостоятельность пациента",
      },
      {
        id: 79,
        question: "Усаживание пациента на кровати выполняют из положения",
        answer: "лёжа на боку",
      },
      {
        id: 80,
        question:
          "Наиболее безопасная позиция тела медсестры при наклоне вперёд",
        answer: "спина прямая с минимальным углом наклона, колени согнуты",
      },
      {
        id: 81,
        question:
          "Уровень помощи пациенту, который адекватен и способен самостоятельно передвигаться",
        answer: "независимый",
      },
      {
        id: 82,
        question: "В бессознательном состоянии пациент находится в положении",
        answer: "пассивное",
      },
      {
        id: 83,
        question:
          "При перемещении с кровати на креслокаталку двумя лицами используют метод",
        answer: "поднятие плечом",
      },
      {
        id: 84,
        question: "Наука о движении и перемещении",
        answer: "кинезиология",
      },
      {
        id: 85,
        question: "Стул подобран правильно, если на сидении располагается",
        answer: "2/3 длины бёдер сидящего",
      },
      {
        id: 86,
        question:
          "При транспортировке пациента медсестра несет ответственность за его",
        answer: "безопасность",
      },
      {
        id: 87,
        question:
          "Способ транспортировки пациента из приемного покоя в отделение определяет",
        answer: "врач",
      },
      {
        id: 88,
        question:
          "Наиболее высокий риск несчастных случаев в стационаре связан с",
        answer: "падениями",
      },
      {
        id: 89,
        question: "Автор положения полулежа полусидя",
        answer: "Фаулер",
      },
      {
        id: 90,
        question: "Экспозиция при применении грелки",
        answer: "15-20 минут",
      },
      {
        id: 91,
        question: "При перемещении пациента в постели медсестре следует",
        answer: "придать пациенту в постели горизонтальное положение",
      },
      {
        id: 92,
        question:
          "Подручные средства для придания пациенту нужного положения в постели",
        answer: "валики",
      },
      {
        id: 93,
        question:
          "Положение лёжа на спине, ставится упор под углом 90 градусов у лежачего пациента для",
        answer: "предупреждения провисания стопы",
      },
      {
        id: 94,
        question:
          "Какая температура воды необходима при постановке горчичников",
        answer: "40-45",
      },
      {
        id: 95,
        question:
          "Ход предстоящей манипуляции перемещения медработнику необходимо знать для",
        answer: "определения объёма предстоящей работы, выработки тактики",
      },
      {
        id: 96,
        question: "Наиболее высокий риск падений у пациентов в возрасте",
        answer: "60-70",
      },
      {
        id: 97,
        question:
          "Прибор для выслушивания тонов караткова на плечевой артерии для выслушивания АД",
        answer: "Фонендоскоп",
      },
      {
        id: 98,
        question: "При перемещении пациента в постели медсестре следует",
        answer: "убрать подушку и одеяло",
      },
      {
        id: 99,
        question:
          "Для смены постельного белья продольным способом пациенту, находящемся на строгом постельном режиме, придают положение",
        answer: "на боку",
      },
      {
        id: 100,
        question:
          "Во время перемещения пациента, положение спины медсестры должно быть",
        answer: "прямым",
      },
      {
        id: 101,
        question: "Положение Фаулера пациент занимает",
        answer:
          " лежа на спине с приподнятым изголовьем кровати под углом 45-60 градусов",
      },
      {
        id: 102,
        question: "Пациенту при строгом постельном режиме",
        answer: "запрещается самостоятельное движение в кровати",
      },
      {
        id: 103,
        question: "При общении с пациентом вопросы следует задавать",
        answer: "тактично, давая пациенту время на ответы",
      },
      {
        id: 104,
        question: "Дренажное положение пациента способствует",
        answer: "отхождению мокроты",
      },
      {
        id: 105,
        question: "В общении с пациентами медсестре следует проявлять",
        answer: "эмпатию",
      },
      {
        id: 106,
        question: "В общении с коллегами, руководством медсестре следует",
        answer: "соблюдать субординацию",
      },
      {
        id: 107,
        question:
          "Уважение частной жизни человека со стороны медработника предполагает",
        answer: "сохранение тайны о состоянии его здоровья",
      },
      {
        id: 108,
        question: "Способность к сопереживанию и сочувствию к другим",
        answer: "эмпатия",
      },
      {
        id: 109,
        question:
          "К внутреннему фактору содействующему совершенствованию  трудовой деятельности, стремлению к карьерному росту относится",
        answer: "профессионализм",
      },
      {
        id: 110,
        question:
          "Ведущим критерием оценки работоспособности медсестры является",
        answer: "состояние функционирования органов и систем организма",
      },
      {
        id: 111,
        question:
          "Временное понижение трудоспособности организма, наступающее в период интенсивной работы и исчезающее после отдыха называется",
        answer: "утомлением",
      },
      {
        id: 112,
        question:
          "При транспортировке пациента в креслекаталке представляет опасность нахождение рук",
        answer: "за пределами подлокотников",
      },
      {
        id: 113,
        question: "Тело умершего пациента транспортируют в",
        answer: "патологоанатомическое отделение",
      },
      {
        id: 114,
        question: "Объем санитарной обработки пациента определяет",
        answer: "врач приемного отделения",
      },
      {
        id: 115,
        question:
          "При сбалансированном питании в ежедневном рационе белки должны составлять",
        answer: "10-15% от суточного рациона",
      },
      {
        id: 116,
        question:
          "В ежедневном рационе содержание овощей и фруктов должно составлять",
        answer: "не менее 400 грамм",
      },
      {
        id: 117,
        question:
          "При лечении сахарного диабета 2 типа рекомендуют соблюдать диету",
        answer: "с пониженной калорийностью",
      },
      {
        id: 118,
        question: "На какое время необходимо накладывать горчичники",
        answer: "10-15 минут",
      },
      {
        id: 119,
        question: "Целью смены подгузника является",
        answer:
          "предупреждение мацерация (изъязвление) промежности мочой и калом",
      },
      {
        id: 120,
        question:
          "Смена подгузника тяжелобольному пациенту должна проводиться каждые",
        answer: "4 часа",
      },
      {
        id: 121,
        question: "Перед сменой подгузника необходимо",
        answer: "повести туалет наружных половых органов",
      },
      {
        id: 122,
        question: "Складки на подгузнике расправляют для ",
        answer: "лучшего прилегание к телу пациента",
      },
      {
        id: 123,
        question:
          "Поверхность судна, которая будет соприкасаться с кожей пациента должна быть",
        answer: "сухая",
      },
      {
        id: 124,
        question:
          "После физиологических отправлений на судне пациенту необходимо",
        answer: "провести туалет НПО",
      },
      {
        id: 125,
        question: "При подаче судна пациенту необходимо придать положение",
        answer: "Фаулера",
      },
      {
        id: 126,
        question: "После использования судна необходимо подвергнуть",
        answer: "дезинфекци",
      },
      {
        id: 127,
        question: "При проведении личной гигиены пациента применяют перчатки",
        answer: "нестерильные медицинские",
      },
      {
        id: 128,
        question: "Последовательность слоев в согревающем компрессе",
        answer: "влажная салфетка, компрессная бумага, вата и бинт",
      },
      {
        id: 129,
        question:
          "Для постановки согревающего компресса необходимо приготовить",
        answer:
          "восьмислойную салфетку, компрессную бумагу, вату, этиловый спирт и бинт",
      },
      {
        id: 130,
        question:
          "Планово пациентам кислородотерапию проводят через носовой катетер со скоростью",
        answer: "2-4 литра в минуту",
      },
      {
        id: 131,
        question: "Регулярность опорожнения кишечника пациента контролирует",
        answer: "медсестра",
      },
      {
        id: 132,
        question: "Судно, подаваемое тяжелобольному должно быть",
        answer: "тёплое и с небольшим количеством воды",
      },
      {
        id: 133,
        question: "Электронный термометр после использования",
        answer: "протирается антисептической салфеткой",
      },
      {
        id: 134,
        question:
          "С целью предупреждения возникновения пищевых отравлений среди пациентов запрещены передачи",
        answer: "кондитерских изделий с кремом",
      },
      {
        id: 135,
        question:
          "Проверять состояние носовой канюли, скорость подачи кислорода каждые",
        answer: "6-8 часов",
      },
      {
        id: 136,
        question: "При отеке лёгких для увлажнения кислорода применяют",
        answer: "70% этиловый спирт",
      },
      {
        id: 137,
        question:
          "Если согревающий компресс наложен правильно, то после снятия компресса марлевая салфетка должна быть",
        answer: "теплая и влажная",
      },
      {
        id: 138,
        question: "Грелку рекомендуется заполнять водой",
        answer: "на 2/3 объёма",
      },
      {
        id: 139,
        question:
          "Преимущество подачи кислорода через носовой катетер-подача кислорода с малой скоростью",
        answer: "2-4 литра в минуту",
      },
      {
        id: 140,
        question: "Влияние тепла на организм",
        answer: "повышает температуру тканей при местном применении",
      },
      {
        id: 141,
        question: "Какой неблагодарный эффект вызывает тепловые процедуры",
        answer: "ожоги",
      },
      {
        id: 142,
        question: "Местное применение холода вызывает",
        answer: "замедление метаболизма и сужение кровеносных сосудов",
      },
      {
        id: 143,
        question: "Длительное воздействие холода приводит к",
        answer: "повреждению тканей из-за дефицита кислорода",
      },
      {
        id: 144,
        question: "ВПоказания к применению грелки",
        answer: "озноб",
      },
      {
        id: 145,
        question: "Противопоказания к применению грелки",
        answer: "острый аппендицит",
      },
      {
        id: 146,
        question:
          "Полная гигеническая обработка пациентов в условиях стационара (при отсутствии мед противопоказаний) должна осуществляться",
        answer: "1 раз в 7 дней",
      },
      {
        id: 147,
        question: "Показания к использованию горчичников",
        answer: "приступ стенокардии",
      },
      {
        id: 148,
        question: "Глаза тяжелобольного обрабатывают по направлению от",
        answer: "от наружного угла глаза к внутреннему",
      },
      {
        id: 149,
        question: "При обработке носовых ходов ватный жгутик смазывают",
        answer: "вазелиновым маслом",
      },
      {
        id: 150,
        question: "Смену постельного белья можно осуществлять двумя способами",
        answer: "продольным и поперечным",
      },
      {
        id: 151,
        question: "Цель применения очистительной клизмы",
        answer: "опорожнение кишечника",
      },
      {
        id: 152,
        question: "Противопоказание к постановке клизм",
        answer: "новообразования прямой кишки",
      },
      {
        id: 153,
        question:
          "Глубина введения ректального наконечника при очистительной клизме",
        answer: "8-10",
      },
      {
        id: 154,
        question: "Температура воды для постановки очист клизмы ",
        answer: "23-25",
      },
      {
        id: 155,
        question: "Обьем воды для постановки очист клизмы",
        answer: "1,2-1,5л",
      },
      {
        id: 156,
        question: "Опорожнительный эффект очист клизмы наступает",
        answer: "через 5-10 минут",
      },
      {
        id: 157,
        question: "Метеоризм является показанием",
        answer: "к постановке газоотводной трубки",
      },
      {
        id: 158,
        question: "Газоотводная трубка может вводиться",
        answer: "неск раз в сутки",
      },
      {
        id: 159,
        question: "Для постановки гипертонической клизмы используют",
        answer: "10% раствор натрия хлорида",
      },
      {
        id: 160,
        question: "Перед постановкой лекарственной клизмы необходимо",
        answer: "за 20-30 минут поставить очистительную клизму",
      },
      {
        id: 161,
        question: "Противопоказание к постановке сифонной клизмы",
        answer: "кишечное кровотечение",
      },
      {
        id: 162,
        question:
          "После постановки масляной клизмы опорожнение кишечника происходит",
        answer: "через 8-10 часов",
      },
      {
        id: 163,
        question: "Газоотводная трубка вводится в кишечник не более чем на",
        answer: "1 час",
      },
      {
        id: 164,
        question: "К частичной гигиенической обработке относится",
        answer: "обтирание",
      },
      {
        id: 165,
        question:
          "Уровень деконтаминации рук мед сестры перед постановкой очист клизмы",
        answer: "гигиенический",
      },
      {
        id: 166,
        question: "Гигиеническую ванну при купании пациента наполняют",
        answer: "до мечевидного отростка",
      },
      {
        id: 167,
        question: "В первую очередь при купании пациенту моют",
        answer: "голову",
      },
      {
        id: 168,
        question: "Волосы тяжелобольного необходимо расчесывать",
        answer: "ежедневно",
      },
      {
        id: 169,
        question:
          "При дефиците личной гигиены у пациента мед сестра проводит частичную санитарную обработку",
        answer: "ежедневно",
      },
      {
        id: 170,
        question: "Стрижка ногтей проводится",
        answer: "раз в 7 дней",
      },
      {
        id: 171,
        question:
          "При метеоризме газоотводная трубка вводится в кишечник на глубину",
        answer: "20-30 см",
      },
      {
        id: 172,
        question:
          "Терминальное состояние которое может длиться от несколько минут до несколько часов",
        answer: "агония",
      },
      {
        id: 173,
        question:
          "Кратковременная потеря сознания связанная с уменьшением притока крови к головному мозгу называется",
        answer: "обморок",
      },
      {
        id: 174,
        question: "Терминальным состоянием называется",
        answer: "пограничное состояние между жизнью и смертью",
      },
      {
        id: 175,
        question: "Терминальной паузой называется",
        answer: "кратковременная остановка дыхания",
      },
      {
        id: 176,
        question: "После констатации смерти пациента необходимо",
        answer: "снять одежду, закрыть глаза, подвязать нижнюю челюсть",
      },
      {
        id: 177,
        question: "В сопроводительной записке к трупу указывают",
        answer: "фио, номер истории болезни, дату смерти, диагноз",
      },
      {
        id: 178,
        question: "Характерным признаком предагонального состояния является",
        answer: "резкая тахикардия",
      },
      {
        id: 179,
        question: "Характерным признаком агонального состояния является",
        answer: "брадикардия ",
      },
      {
        id: 180,
        question:
          "Противопоказанием для проведения ремационных мероприятий является",
        answer: "появление признаков биологической смерти",
      },
      {
        id: 181,
        question: "Обьем заполнения ложки пищей при кормлении составляет",
        answer: "2/3",
      },
      {
        id: 182,
        question: "При гемопорезе пища на ложке подносится",
        answer: "со здоровой стороны",
      },
      {
        id: 183,
        question:
          "При кормлении пациента с помощью ложки необходимо придать ему положение",
        answer: "Фаулера",
      },
      {
        id: 184,
        question: "После каждого приема пищи необходимо",
        answer: "дать пациенту прополоскать рот",
      },
      {
        id: 185,
        question: "Механическая обработка пищи предусматривает",
        answer: "измельчение",
      },
      {
        id: 186,
        question: "Искусственным питанием называется",
        answer: "введение питательных веществ в организм минуя ротовую полость",
      },
      {
        id: 187,
        question: "Рациональным питанием называется",
        answer:
          "физиологически полноценное питание с учетом пола возраста и др факторов",
      },
      {
        id: 188,
        question: "Необходимая диета назначается в зависимости от",
        answer: "заболевания",
      },
      {
        id: 189,
        question:
          "Показанием к искусственному питанию через гастростому является",
        answer: "заболевание пищевода",
      },
      {
        id: 190,
        question: "Искусственное кормление можно осуществить при помощи",
        answer: "гастростомы",
      },
      {
        id: 191,
        question:
          "Больному при строгом постельном режиме, перед введением зонда необходимо придать положение",
        answer: "Фаулера",
      },
      {
        id: 192,
        question:
          "Во избежании пролежней и воспалительных осложнений назогастр зонд рекомендуется извлекать",
        answer: "1 раз в 2 недели",
      },
      {
        id: 193,
        question: "Энергетическая ценность пищи обусловлена всем кроме",
        answer: "воды и минеральных солей",
      },
      {
        id: 194,
        question:
          "Раздачу пищи в отделении МО проводит буфетчица и мед сестра по данным",
        answer: "палатного порционника",
      },
      {
        id: 195,
        question: "В отделении общего профиля кормить больных положено",
        answer: "4 раза в день",
      },
      {
        id: 196,
        question:
          "После дезинфекции посуды для кормления больных его споласкивают проточной водой и подвергают",
        answer: "высушиванию на сетках подставках",
      },
      {
        id: 197,
        question:
          "Минимальная энергетическая потребность пациента находящегося на постельном режиме составляет",
        answer: "2000 кал",
      },
      {
        id: 198,
        question: "Раздачу пищи в лечебных отделениях осуществляет",
        answer: "дежурные мед сестры и буфетчицы",
      },
      {
        id: 199,
        question: "К службе приготовления пищи в МО относятся помещения",
        answer:
          "комплекс (складские, производственные, административно-бытовые, столовые и буфеты)",
      },
      {
        id: 200,
        question: "Мед обследование персонала пищеблока проводится",
        answer: "раз в год",
      },
      {
        id: 201,
        question: "Мытье столовой посуды в стационарах проводится",
        answer:
          "в моечных помещениях буфетов, с соблюдением режима обеззараживания посуды",
      },
      {
        id: 202,
        question: "Режим мытья столовых приборов и посуды включает",
        answer:
          "механическую очистку остатков пищи, обеззараживания, ополаскивания и просушивания",
      },
      {
        id: 203,
        question: "Цианоз кожных покровов",
        answer: "синюшное окрашивание",
      },
      {
        id: 204,
        question: "Число сердечных сокращений в норме",
        answer: "60-90",
      },
      {
        id: 205,
        question: "Свойство артериального пульса",
        answer: "наполнение",
      },
      {
        id: 206,
        question: "Для измерения АД используют прибор",
        answer: "тонометр",
      },
      {
        id: 207,
        question:
          "Обещепринятая среднестатистическая норма АД у взрослого человека",
        answer: "120/80",
      },
      {
        id: 208,
        question:
          "Антропометрия включает измерение всех перечисленных параметров кроме",
        answer: "силы",
      },
      {
        id: 209,
        question: "К субъективному методу сестринского обследования относится",
        answer: "расспрос пациента",
      },
      {
        id: 210,
        question: "Свойство дыхания",
        answer: "тип",
      },
      {
        id: 211,
        question: "Физиологическая проблема пациента",
        answer: "нарушение сна",
      },
      {
        id: 212,
        question: "При вербальном общении используются",
        answer: "слова",
      },
      {
        id: 213,
        question: "Антропометрия включает измерение",
        answer: "роста",
      },
      {
        id: 214,
        question: "Кратковременная потеря сознания",
        answer: "обморок",
      },
      {
        id: 215,
        question:
          "Пульс взрослого человека в покое составил 98 ударов в минуту",
        answer: "тахикардия",
      },
      {
        id: 216,
        question:
          "Иерархия основных человеч потребностей предложенная американским…",
        answer: "Маслоу",
      },
      {
        id: 217,
        question: "Первичные потребности по Маслоу",
        answer: "физиологические",
      },
      {
        id: 218,
        question:
          "Количество уровней основных жизненноважных потребностей по Маслоу",
        answer: "3",
      },
      {
        id: 219,
        question: "Социальные потребности по Маслоу",
        answer: "уровень 3",
      },
      {
        id: 220,
        question: "Маслоу изобразил уровни потребности в виде",
        answer: "пирамиды",
      },
      {
        id: 221,
        question: "Первичные потребности по Маслоу",
        answer: "безопасности",
      },
      {
        id: 222,
        question: "по Маслоу сон потребность",
        answer: "физиологическая",
      },
      {
        id: 223,
        question: "Низший уровень потребности по Маслоу",
        answer: "физиологический",
      },
      {
        id: 224,
        question: "вторичные потребности по Маслоу",
        answer: "безопасности",
      },
      {
        id: 225,
        question: "потребность в уважении окружающих уровень по маслоу",
        answer: "4",
      },
      {
        id: 226,
        question: "вершина пирамиды Маслоу",
        answer: "самоактуализация",
      },
      {
        id: 227,
        question: "физиологическая потребность по Маслоу",
        answer: "движение",
      },
      {
        id: 228,
        question: "Успех",
        answer: "потребность самоуважения",
      },
      {
        id: 229,
        question: "потребность в защите по Маслоу",
        answer: " уровень 2",
      },
      {
        id: 230,
        question:
          "Количество физиологических потребностей пациента по модели Вержинии Хендерсон",
        answer: "14",
      },
      {
        id: 231,
        question: "Антропометрическое исследование включает определение",
        answer: "массы тела",
      },
      {
        id: 232,
        question: "Повышение артериального давления",
        answer: "гипертензия",
      },
      {
        id: 233,
        question: "Тахипноэ",
        answer: "учащенное дыхания",
      },
      {
        id: 234,
        question: "Пульс более 80 ударов в минуту",
        answer: "тахикардия",
      },
      {
        id: 235,
        question: "Пульс менее 60 ударов в минуту",
        answer: "брадикардия",
      },
      {
        id: 236,
        question: "Приоритетная физиологическая проблема пациента",
        answer: "боль",
      },
      {
        id: 237,
        question: "Потенциальная физиологическая проблема пациента",
        answer: "риск появления опрелостей",
      },
      {
        id: 238,
        question: "Физиологическая проблема пациента",
        answer: "нарушения сна",
      },
      {
        id: 239,
        question: "Медицинская эргономика",
        answer:
          "это наука, изучающая- особенности трудовых процессов в медицине",
      },
      {
        id: 240,
        question:
          "Основную физическую нагрузку при поднятии и перемещении тяжести должны нести мышцы",
        answer: "бедер",
      },
      {
        id: 241,
        question: "Особенности трудовых вопросов в медицине",
        answer:
          "Сохранение равновесия тела при перемещении тяжести зависит от - точки опоры , веса мед сестры и осанки",
      },
      {
        id: 242,
        question: "Первое действие мед сестры при перемещении пациента",
        answer: "оценить окружающую обстановку и свои возможности",
      },
      {
        id: 243,
        question:
          "К вспомогательным средствам перемещения пациента с кровати на каталку относится",
        answer: "скользящая простынь",
      },
      {
        id: 244,
        question:
          "Перемещая пациента к изголовью кровати для его безопасности следует",
        answer: "поставить подушку к спинке кровати у головного края",
      },
      {
        id: 245,
        question:
          "Спина мед сестры при подъёме и перемещении пациента или груза должна быть",
        answer: "максимально прямой для данного вида перемещения",
      },
      {
        id: 246,
        question: "Правильная биомеханика человека в положении сидя",
        answer:
          "2/3 длины бедер находятся на сиденье, стопы без напряжения касаются пола",
      },
      {
        id: 247,
        question:
          "Для облегчения подъёма и перемещения тяжестей рекомендуется приблизить к себе предмет",
        answer: "вплотную",
      },
      {
        id: 248,
        question:
          "Для того, чтобы повернуться, находясь в положении стоя в начале необходимо повернуть",
        answer: "ступни",
      },
      {
        id: 249,
        question: "Правильная биомеханика тела мед сестры позволяет",
        answer: "предотвращать травмы позвоночника",
      },
      {
        id: 250,
        question: "При перемещении пациента в постели мед сестре следует",
        answer: "придать кровати горизонтальное положение",
      },
      {
        id: 251,
        question: "Наиболее взаимосвязанные свойства пульса",
        answer: "наполнение и напряжение",
      },
      {
        id: 252,
        question: "По наполнению пульс различают",
        answer: "полный и пустой",
      },
      {
        id: 253,
        question: "В первом периоде лихорадке",
        answer: "грелка",
      },
      {
        id: 254,
        question: "В 3 периоде лихорадки необходимо применение",
        answer: "прохладного витаминизированного питья",
      },
      {
        id: 255,
        question:
          "Возможная локация пролежней на передней поверхности туловища область",
        answer: "коленных суставов",
      },
      {
        id: 256,
        question: "К дефектам окр среды относят",
        answer: "плохое освещение",
      },
      {
        id: 257,
        question: "Факторы риска образования пролежней по шкале Нортон",
        answer: "физическое состояние",
      },
      {
        id: 258,
        question:
          "Большинство нуждающихся в палиативной помощи взрослых страдают такими заболеваниями как",
        answer:
          "сердечно сосудистые, рак, хронические респираторные, спид, диабет ",
      },
      {
        id: 259,
        question: "В России порядок оказания палиативной помощи регулирует",
        answer: "федеральный закон 323",
      },
      {
        id: 260,
        question: "В России хосписов",
        answer: "не хватает",
      },
      {
        id: 261,
        question: "Инкуррабельный",
        answer: "неизлечимый",
      },
      {
        id: 262,
        question: "Палиативная мед помощь оказывается",
        answer:
          "в амбулаторных условиях, в том числе на дому, дневного стационара и стационарных условиях",
      },
      {
        id: 263,
        question: "Палиативная мед помощь решает проблемы",
        answer: "физические, психосоциальные, духовные",
      },
      {
        id: 264,
        question:
          "Пациенты палиативной медицины чаще всего ослаблены, имеют нарушения обмена веществ, нарушения работы иммунной системы, системы кровообращения. Поэтому для таких пациентов жизненно необходимо",
        answer: "неукоснительное соблюдение санпин режима мед работниками",
      },
      {
        id: 265,
        question: "Принцип палиативной помощи",
        answer:
          "Создание защиты от тягостных проявлений болезни но не лечение самой болезнии",
      },
      {
        id: 266,
        question: "Роль мед сестры в палиативной медицине",
        answer:
          "является крайне важной так как мед сестра знает все особенности и нюансы проблем пациента его привычки желания потребности",
      },
      {
        id: 267,
        question: "При смене постельного белья пациенту грязной белье помещают",
        answer: "в непромокаемый мешок для грязного белья",
      },
      {
        id: 268,
        question:
          "Уход за естественными складками кожи тяжелобольного проводят",
        answer: "2 раза в день",
      },
      {
        id: 269,
        question: "Перед утренним гигиеничным уходом за пациентом необходимо",
        answer:
          "представиться объяснить ход и цель процедуры получить согласие обработать руки надеть перчатки",
      },
      {
        id: 270,
        question:
          "Целью помощи пациенту в осуществлении личной гигиены является",
        answer: "обеспечение комфорта чистоты безопасности",
      },
      {
        id: 271,
        question: "К принципам ухода за пациентом относятся",
        answer: "безопасность и конфиденциальность",
      },
      {
        id: 272,
        question:
          "При смене нательного белья пациенту с поврежденной верхней конечностью необходимо",
        answer: "вначале надеть рукав на больную конечность",
      },
      {
        id: 273,
        question: "При осуществлении ухода за НПО",
        answer: "мед сестра выполняет движения салфеткой сверху вниз",
      },
      {
        id: 274,
        question: "Недержание мочи на простыню необходимо поместить",
        answer: "впитывающую пеленку",
      },
      {
        id: 275,
        question: "Чистку Зубов тяжелобольному начинают",
        answer: "с задних зубов",
      },
      {
        id: 276,
        question:
          "При осуществлении ухода за полостью рта тяжелобольного обработку языка начинают",
        answer: "от корня к кончику",
      },
      {
        id: 277,
        question: "Смену рубашки у пациента начинают",
        answer: "со здоровой стороны, затем с поврежденной",
      },
      {
        id: 278,
        question: "Уход за ротовой полостью тяжелобольному (день)",
        answer: "2 раза",
      },
      {
        id: 279,
        question:
          "С целью профилактики ротовой полость и трещин на губах у пациента",
        answer: "необходимо смазывать губы глицерином",
      },
      {
        id: 280,
        question:
          "С целью предотвращения трещин на губах у пациента необходимо",
        answer: "смазывать губы глицерином",
      },
      {
        id: 281,
        question: "Ногти на ногах подстригают",
        answer: "по прямой линии",
      },
      {
        id: 282,
        question:
          "Наиболее частой областью образования пролежней у тяжелобольного при положении на боку",
        answer: "область тазобедренного сустав",
      },
      {
        id: 283,
        question: "При недержании мочи в ночные часы желательно использоват",
        answer: "подгузники",
      },
      {
        id: 284,
        question: "Для обработки слизистой ротовой полости можно использоват",
        answer: "палочки пагавит",
      },
      {
        id: 285,
        question: "Зубную щётку при чистке зубов необходимо располагать",
        answer: "под углом 45",
      },
      {
        id: 286,
        question: "Умывание пациента проводят",
        answer: "утром",
      },
      {
        id: 287,
        question: "Для умывания пациента готовят",
        answer: "таз с водой, одноразовые рукавички, средство для ухода",
      },
      {
        id: 288,
        question: "Мытьё ног тяжело больному пациенту проводят",
        answer: "2-3 раза в неделю",
      },
      {
        id: 289,
        question:
          "При появлении трещин на губах пациенту необходимо смазать губы",
        answer: "вазелиновым маслом",
      },
      {
        id: 290,
        question: "Достоверным признаком клинической смерти является",
        answer: "отсутствие дыхания",
      },
      {
        id: 291,
        question: "Достоверным признаком биологической смерти является",
        answer: "кошачий глаз",
      },
      {
        id: 292,
        question: "Необратимым этапом умирания организма является",
        answer: "биологическая смерть",
      },
      {
        id: 293,
        question: "Для клинической смерти характерно",
        answer: "отсутствие сознания и дыхания",
      },
      {
        id: 294,
        question: "Факт биологической смерти пациента",
        answer: "констатирует врач",
      },
      {
        id: 295,
        question: "Биологическая смерть наступает",
        answer: "через 3-6 минут после клинической смерти",
      },
      {
        id: 296,
        question: "Для биологической смерти характерно",
        answer: "появление трупных пятен",
      },
      {
        id: 297,
        question: "Холодный компресс необходимо менять",
        answer: "каждые 2-3 минуты",
      },
      {
        id: 298,
        question:
          "Пациенту находящемуся во втором периоде лихорадки с целью облегчения состояния необходимо применить на область лба",
        answer: "холодный компресс",
      },
      {
        id: 299,
        question: "Побочное действие при оксигенотерапии",
        answer: "сухость и жжение в носоглотке",
      },
      {
        id: 300,
        question: "При длительном воздействии горчичников возможен",
        answer: "ожог с образованием пузырей",
      },
      {
        id: 301,
        question: "Основными показаниями к оксигенотерапии является",
        answer: "гипоксия и гипоксимия",
      },
      {
        id: 302,
        question: "Факторы, способствующие образованию пролежней",
        answer: "парализация пациента, недоедание мочи и кала",
      },
      {
        id: 303,
        question:
          "Выберите пациентов с нормальными показателями АД и частотой пульса",
        answer:
          "частота пульса-68/мин, АД-110/70 мм рт.ст; частота пульса-76/мин, АД-120/80",
      },
      {
        id: 304,
        question:
          "Выберите пациентов с нормальными показателями АД и частотой пульса",
        answer: "пульс 70/мин, АД-115/75; пульс 80/мин, АД-120/80",
      },
      {
        id: 305,
        question: "Достоверными признаками биологической смерти являются",
        answer: "наличие симптома кошачий глаз, трупные пятна",
      },
      {
        id: 306,
        question: "Признаками клинической смерти являются",
        answer: "отсутствие дыхания, зрачки широкие не реагируют на свет",
      },
      {
        id: 307,
        question: "Признаками биологической смерти являются",
        answer: "симптом кошачьего глаза, высыхание роговицы глаза",
      },
      {
        id: 308,
        question: "К видам питания по способу принятия пищи относят",
        answer: "естественное, искусственное",
      },
      {
        id: 309,
        question: "Рациональное питание пациента определяется",
        answer: "оптимальным соотношением пищи БЖУ, режимом питания",
      },
      {
        id: 310,
        question: "Энтеральное питание используется при",
        answer: "нарушение сознания, опухоли в области ротоглотки",
      },
      {
        id: 311,
        question: "Способы организации питания больных в стационаре",
        answer: "централизованный, децентрализованный",
      },
      {
        id: 312,
        question: "Принципы работы пищеблока",
        answer:
          "принцип поточности технологического процесса, принцип раздельности",
      },
      {
        id: 313,
        question:
          "К оборудованию для перемещения пациентов в кровати относится",
        answer: "скользящие доски, скользящие простыни",
      },
      {
        id: 314,
        question: "Искусственное кормление можно осуществить при помощи",
        answer: "назогастрального зонда, гастростомы",
      },
      {
        id: 315,
        question: "К стопам кишечника относят",
        answer: "илеостома, калостома",
      },
      {
        id: 316,
        question: "Показанием для стомирования могут быть",
        answer: "врожденные аномалии, новообразования",
      },
      {
        id: 317,
        question: "Стомы в зависимости от формы выведения кишки бывают",
        answer: "втянутые и выпуклые, прямые и поперечные",
      },
      {
        id: 318,
        question: "Виды калоприёмников",
        answer:
          "однокомплнентные и двухкомплиментные, дренируемые и не дренируемые",
      },
      {
        id: 319,
        question: "К возможностям паллиативной помощи можно отнести",
        answer:
          "повышение качества жизни, применение на ранних стадиях заболевания в сочетании с другими методами лечения, проведение исследований с целью лучшего понимания и лечения клинических симптомов и осложнений",
      },
      {
        id: 320,
        question:
          "Пациентам со следующими заболеваниями может потребоваться следующая паллиативная помощь",
        answer:
          "врожденные пороки развития, деменция, рассеянный склероз, туберкулез с лекарственной устойчивостью",
      },
      {
        id: 321,
        question: "Внешние факторы риска падения",
        answer: "неровные полы, плохое освещение, тесная обувь",
      },
      {
        id: 322,
        question: "Охлаждающий компресс способствует",
        answer: "сужению сосудов, снижению отёков, утолению боли",
      },
      {
        id: 323,
        question: "Грелку применяют с целью",
        answer: "снятие спазма, согревание тела, рассасывание инфильтрата",
      },
      {
        id: 324,
        question: "Охлаждающий эффект пузыря со льдом связан с действием",
        answer: "отвлекающим, кровеостанавливающим, болеутоляющим",
      },
      {
        id: 325,
        question: "Очистительная клизма применяется при",
        answer:
          "запорах, перед родами, перед хирургической операцией, перед постановкой лекарственной клизмы",
      },
      {
        id: 326,
        question: "Механизм действия очистительной клизмы",
        answer:
          "усиление перестальтики, разрыхление и облегчение выведения кала, очищающее действие",
      },
      {
        id: 327,
        question: "Противопоказанием для постановки клизмы",
        answer:
          "кровоточащий геморрой, выпадение прямой кишки, кишечные кровотечения",
      },
      {
        id: 328,
        question: "Источники информации о пациенте",
        answer: "пациент, семья, медперсонал, история болезни",
      },
      {
        id: 329,
        question: "Характеристики артериального пульса",
        answer: "наполнение, напряжение, ритмичность, симметричность, частота",
      },
      {
        id: 330,
        question:
          "Какие условия обязательно должны быть соблюдены перед стандартным измерением АД",
        answer:
          "через 1-2 часа после еды, через 1 час после курения, через 1 час после приёма кофе",
      },
      {
        id: 331,
        question:
          "Какие симптомы являются достоверными признаками биологической смерти",
        answer:
          "появление трупных пятен, снижение температуры кожи ниже 20 градусов, появление трупного окоченение",
      },
      {
        id: 332,
        question:
          "Пациент в зависимости от общего состояния занимает различные положения в постели",
        answer: "активное, пассивное, вынужденное",
      },
      {
        id: 333,
        question:
          "Выберите оснащение необходимое для придания пациенту положения Фаулера",
        answer:
          "функциональная кровать, матрац, подушки или валики, упор для стоп",
      },
      {
        id: 334,
        question: "Показания к применению горчичников",
        answer:
          "воспалительные заболевания нижних дыхательных путей, гипертонический криз, невралгии",
      },
      {
        id: 335,
        question: "Показания к применению пузыря со льдом",
        answer:
          "ровотечение, высокая лихорадка (2 период), гематомы в первые часы после ушиба",
      },
      {
        id: 336,
        question: "Противопоказания к применению грелки",
        answer: "злокачественные опухоли, неясные боли в животе, кровотечения",
      },
      {
        id: 337,
        question: "Противоэпидемический режим на пищеблоке включает",
        answer: "обработку посуды, генеральная уборка, кварцевание",
      },
      {
        id: 338,
        question: "Принципы питания пожилых пациентов включают",
        answer:
          "стимуляция активности ферментных систем организма, антисклеротическая направленность рациона ограничение животных жиров, калорийность рациона должна соответствовать энергозатратам",
      },
      {
        id: 339,
        question: "К работе на пищеблоке не допускаются",
        answer:
          "лица переболевшие паратитом, дизентерией и брюшным тифом, лица болеющие активной формой туберкулеза, лица имеющие гнойничковые поражения кожи",
      },
      {
        id: 340,
        question:
          "К факторам требующим коррекции рациона в пожилом возрасте относят",
        answer:
          "снижение физ активности, наличие хронических заболеваний, снижение функциональных возможностей ферментативный систем",
      },
      {
        id: 341,
        question: "Характер питания человека определяет его",
        answer: "обмен веществ, функции органов и систем, конституция",
      },
      {
        id: 342,
        question: "Режим питания предполагает",
        answer: "кратность приема, время приема, интервал приемов",
      },
      {
        id: 343,
        question: "К запорам при наличии колостомы ведет",
        answer:
          "малоподвижный образ жизни, употребление жидкости менее 1500 мл в день, употребление легкоусвояемых блюд ",
      },
      {
        id: 344,
        question: "Трахеостомичесие трубки могут быть",
        answer: "с одной манжетой, с двумя манжетами, без манжетов",
      },
      {
        id: 345,
        question: "Слои согревающего компресса",
        answer: "салфетка, компрессная бумага, вата",
      },
      {
        id: 346,
        question: "Выберите основные способы оксигенотерапии",
        answer: "назальный катетер, носовая канюля, лицевая маска",
      },
      {
        id: 347,
        question: "Выберите верные утверждения",
        answer:
          "тепловые процедуры обладают болеутоляющим и спазматилическим эффектом, лекарственный компресс обладает заживлявший действием, перед применением лекарственного компресса кожу смазывают кремом",
      },
      {
        id: 348,
        question:
          "Метод пересаживания пациента на каталку через вставание выполняется следующим образом",
        answer:
          "пациент опирается ближней к креслу рукой о дальний подлокотник, пациент опирается дальней рукой о край кровати сбоку туловища или бедро, пациент приподнимает туловище опираясь руками и ногами, пациент поворачивает таз в сторону кресла и садится на его сиденье, медсестра устанавливает подставку для ног, медсестра снимает кресло-каталку с тормоза",
      },
      {
        id: 349,
        question:
          "Последовательность действий медсестры при транспортировке пациента на каталке",
        answer:
          "проверьте исправность каталки, заправьте каталку чистой простынью и одеялом, уложите пациента на каталку, укройте его, убедитесь что пациент чувствует себя комфортно, следите за тем чтобы руки пациента не выходили за пределы каталки",
      },
      {
        id: 350,
        question: "Установите соответствие между понятием и определением",
        answer:
          "1)Состояние, при котором человек ходит в туалет именно ночью-ночное мочеиспускание (никтурия)2)Состояние, при котором невозможно мочеиспускание-острая задержка мочи (ишурия) 3)Потребность в многократном мочеиспускании в течении дня или ночи, при условии выделения нормального или уменьшено суточного объёма мочи-поллакиурия 4)Количество выделенной мочи за сутки-суточный диурез",
      },
      {
        id: 351,
        question: "Назначение оборудования для перемещения пациента",
        answer:
          "1)Поручни (опорные перилла)-из положения сидя в положение стоя 2)Скользящие диски-с кровати на кресло 3)Ходунки-из палаты в коридор 4)Скользящие простыни-по кровати",
      },
      {
        id: 352,
        question:
          "Уязвимые категории пациентов при термических воздействиях на кожу",
        answer:
          "1)Невосприятие болевых температурных раздражителей-пациенты с поражением спинного мозга, спутанным сознанием, без сознания 2)Снижение болевой температурной чувствительности-престарелые люди 3)Нежность, уязвимость кожных покровов-маленькие дети 4)Снижение количества болевых рецепторов, повышение чувствительности подкожных и подлежащих тканей у перепадам внешних температур-пациенты с открытыми ранами, стомами или нарушением целостности кожных покровов",
      },
      {
        id: 353,
        question: "Места постановки горчичников при различных заболеваниях",
        answer:
          "1)Грудная клетка спереди и сзади, справа боковая поверхность грудной клетки-воспалительные заболевания нижних дыхательных путей (бронхиты и пневмонии) 2)Грудная клетка спереди, икроножные мышцы-воспалительные состояния верхних дыхательных путей (риниты, фарингиты, трахеиты) 3)Затылок, воротниковая зона, икроножные мышцы-гипертонический криз4)Область сердца-стенокардия",
      },
      {
        id: 354,
        question: "Специализированные направления в физиотерапии в медицине",
        answer:
          "1)Воздействие открытого воздуха без прямого солнечного облучения-аэротерапия 2)Солнечное облучение-гелеотерапия3)Пиявки-герундотерапия4)Лечебные воды, грязи-балиотерапия",
      },
      {
        id: 355,
        question: "Виды травм и факторы, способствующие травмированию",
        answer:
          "1. трение - состояние кожных покровов 2. Падения - состояние здоровья пациента 3. Повреждение суставов - большая нагрузка на ноги4. Сопротивление пациента - недостаточность общения",
      },
      {
        id: 356,
        question: "Положение пациента",
        answer:
          "1. Пациент не испытывает дефицита самохода- активное2. Положение в постели для облегчения своего состояния - вынужденное3. Полусидячее положение - для облегчения отдышки - Фаулера4. Пациент не может самостоятельно изменить положение своего состояния - пассивное",
      },
      {
        id: 357,
        question: "ППрименение подушек или валиков при положении Фаулера",
        answer:
          "1. Предупреждается сгибательная контрактура шейных мышц - тонкая подушка под голову2. Предупреждается вывих плеча и сгибательная контрактура мышцы верхних конечностей - подушки под руки пациента 3. Уменьшение нагрузки на поясничный отдел позвоночника - подушка под поясницу4. Предупреждается переразгибание в коленном суставе и сдавление подколенной артерии - валик под бедро 5. Для предупреждения длительного матраца на пятки - небольшая подушка под нижнюю треть голени",
      },
      {
        id: 358,
        question: "Показатели и значение Ад",
        answer:
          "1. 160/90 гипертензия 2. 90/60 гипотензия3. 130/80 нормальный показатель",
      },
      {
        id: 359,
        question: "Характеристика частоты пульса",
        answer:
          "1. 45 брадикардия2. 100 тахикардия 3. 75 нормальный показатель 4. 80 нормальный показатель 5. 51 брадикардия",
      },
      {
        id: 360,
        question:
          "Установите соответствие между количеством баллов и мониторингом эффективности ухода по шкале Бартелло",
        answer:
          "1. Лёгкая зависимость пациента в повседневной жизни - 91-1002. Умеренная зависимость пациента - 61-903. Выраженная зависимость пациента 21-604. Полная зависимость пациента 0-20",
      },
      {
        id: 361,
        question: "В соответствии между способами кормления и оснащением",
        answer:
          "1. Через назогастральный зонд - назогастральный зонд 2. Через гастростому – гаст. трубка3. Парентеральная система - система для питания",
      },
      {
        id: 362,
        question: "Соответствие между видом стомы и целью его",
        answer:
          "1. Опорожнение кишечника - колостома2. Восстановление дыхания - трахеостома3. Кормление - гастростома3. Выведение мочи – цистостома",
      },
      {
        id: 363,
        question: "Соответствие между видом стомы и анатомическим местом",
        answer:
          "1. Тонкий кишечник - илеостома 2. Мочевой пузырь - цистостома3. Толстый кишечник - колостома 4. Почка – Нефростома",
      },
      {
        id: 364,
        question: "Между стадиями терминальной состояния",
        answer:
          "1. Состояние начинается с остановки дыхания и деятельности сердца, нет реакции на свет - клиническая смерть 2. Характеристикой нарушением ЦНС и резким ухудшениеем гемодинамики: сознание ещё сохранено, но пациент заторможен, пульс - преагония3. Характеризуется отсутствием сознанием, но пациент может слышать, резкая бледность кожных покровов с выраженным акроцианозом (мраморностью) – агония",
      },
      {
        id: 365,
        question:
          "Соответствие между стадиями эмоциональными стадиями горевания",
        answer:
          "1. Пациент испытывает растерянность и отчаяния - депрессия2. Желание изоляции от окружающих - неоправданный оптимизм, оцепенение- отрицание3. Обострённое чувство - гнев4. Человек пытается заключить сделку с высшими силами - торг5. Человек возвращается к привычной жизни, начинает возвращаться к жизни – принятие",
      },
      {
        id: 366,
        question:
          "Соответствие между показателями и значениями диастолического ад",
        answer:
          "1. Гипотензия - 40-60 рт столба2. Норма - 70-903. Гипертензия 90-110",
      },
      {
        id: 367,
        question:
          "Соответствие между показателями частоты пульса и изменениями чсс",
        answer:
          "1. Тахикардия - 1002. Норма - 753. Норма - 804. Брадикардия - 455. Брадикардия – 51",
      },
      {
        id: 368,
        question:
          "Помощь пациенту в терминальной стадии удовлетворении потребностей оказывает мед сестра. Установите соответствие между видами помощи",
        answer:
          "1. Мед сестра обеспечивает прием легкоусвояемой пищи малыми порциями 5-6 раз в день - при нарушении удовлетворения потребности есть2. Мед сестра обеспечивает пациенту прием достаточного количества жидкости - при нарушении удовлетворения потребности пить3. Мед сестра поставит очистительную клизму по назначению врача - при нарушение удовлетворения потребности выделять4. Мед сестра придаст пациенту вынужденное положение - при Нарушение удовлетворения потребности дышать",
      },
      {
        id: 369,
        question: "Температура воды для клизмы",
        answer:
          "1. При атоническом запоре 16-20 градусов2. При спастическом запоре 37-38 3. При подготовке к исследованиям и операциям 23-25",
      },
      {
        id: 370,
        question: "Объем вводимой жидкости",
        answer:
          "1. Очистительная - 1 литр2. Сифонная - 10 литров3. Лекарственная 50 мл",
      },
      {
        id: 371,
        question: "Время до наступления эффекта",
        answer:
          "1. Очистительная клизма 10-15 минут2. Сифонная клизма - В процессе процедур3. Лекарственная - опорожнение не наступает",
      },
      {
        id: 372,
        question: "Места применения процедур",
        answer:
          "1. Горчичники - подлопаточная область 2. Горчичники - область грудной клетки3. Пузырь со льдом - область живота 4. Пузырь со льдом - на травмированную конечность",
      },
      {
        id: 373,
        question: "Показание к применению процедуры",
        answer:
          "1. Носовые кровотечение - холодный компресс 2. Миозит - горчичники 3. Невролгия - горчичники 4. Период лихорадка - холодный компресс",
      },
      {
        id: 374,
        question: "Показание к применению процедуры",
        answer:
          "1. Инфильтрат - горячий компресс2. Радикулит - грелка3. Вегето сосудистая дистония - ванны4. Первые часы после любых травм - пузырь со льдом",
      },
      {
        id: 375,
        question: "В процессе бритья пациента станок",
        answer: "ведется в противоположном направлении от оттянутой кожи",
      },
      {
        id: 376,
        question:
          "Измерение окружности грудной клетки на вдохе и на выдохе относится",
        answer: "антропометрии",
      },
      {
        id: 377,
        question:
          "Соотношение количества выпитой и выделенной жидкости называется",
        answer: "водный баланс",
      },
      {
        id: 378,
        question:
          "Смена подгузника тяжелобольному должна проводиться каждые (в часах)",
        answer: "4",
      },
      {
        id: 379,
        question:
          "Перед надеванием подгузника необходимо взять чистый подгузник за",
        answer: "концы, встряхнуть и потянуть",
      },
      {
        id: 380,
        question:
          "При осуществлении туалета наружных половых органов женщины, медицинская сестра выполняет движения салфеткой",
        answer: "сверху вниз",
      },
      {
        id: 381,
        question: "Протирание ресниц и век необходимо выполнять",
        answer: "от наружного угла глаза к внутреннему",
      },
    ],

    term: "",
  };

  searchTerm = (term) => {
    this.setState({ term });
  };

  search(items, term) {
    if (term.length === 0) {
      return items;
    }
    return items.filter((item) => {
      return item.question.toLowerCase().indexOf(term.toLowerCase()) > -1;
    });
  }

  render() {
    const { listAnswers, term } = this.state;

    const visibleItems = this.search(listAnswers, term);

    return (
      <div className="app">
        <SearchPanel searchTerm={this.searchTerm} />
        <ul className="list">
          {visibleItems.map((item, i) => (
            <ListItem item={item} i={i} />
          ))}
        </ul>
      </div>
    );
  }
}

export { App };
